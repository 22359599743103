import { createSlice } from '@reduxjs/toolkit';

const scrollPositionSlice = createSlice({
  name: 'scrollPosition',
  initialState: {
    position: 0,
  },
  reducers: {
    setScrollPosition: (state, action) => {
      state.position = action.payload;
    },
    clearScrollPosition: (state) => {
      state.position = 0;
    },
  },
});

export const { setScrollPosition, clearScrollPosition } = scrollPositionSlice.actions;
export default scrollPositionSlice.reducer;