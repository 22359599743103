import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import Product from '../../components/Product/Product';
import styles from './Produse.module.css';
import { fetchData, getDataGraphQL } from '../../utils/fetchData';
import { getProductsWithFilters } from '../../utils/graphQL/productQueries';
import { getSubCategoriesByCategoryId } from '../../utils/graphQL/categoryQueries';
import { hideLoading, showLoading } from '../../features/ui/uiSlice';
import showModal from '../../utils/modalFunction';
import Pagination from '../../components/Pagination/Pagination';
import { clearScrollPosition, selectScrollPosition } from '../../features/scrolling/scrollPositionSlice';


const Produse = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { tip_produs } = useParams();
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [beCategories, setBeCategories] = useState([]);
    const [beSubcategories, setBeSubcategories] = useState([]);
    const [pagination, setPagination] = useState(null);
    const userCountry = useSelector(state => state.user.userDetails.tara);
    const scrollPosition = useSelector(state => state.scrollPosition.position);
    const [productsLoaded, setProductsLoaded] = useState(false);

    const { selectedCategories, selectedSubcategories, oferta, sortOrder } = useMemo(() => ({
        selectedCategories: searchParams.getAll('categorie'),
        selectedSubcategories: searchParams.getAll('subcategorie'),
        oferta: searchParams.get('oferta') === 'true',
        sortOrder: searchParams.get('sort') || 'desc'
    }), [searchParams]);

    const fetchCategories = useCallback(async () => {
        try {
            const data = await fetchData(`/categorie?where[tip_produs][equals]=${tip_produs}`, dispatch);
            setBeCategories(data.docs);
        } catch (error) {
            showModal(dispatch, 'A intervenit o eroare: ', error);
            dispatch(hideLoading());
        }
    }, [dispatch, tip_produs]);

    const fetchSubcategories = useCallback(async () => {
        if (selectedCategories.length === 0) {
            setBeSubcategories([]);
            return;
        }
        try {
            dispatch(showLoading());
            const searchParameters = { categoryIds: selectedCategories };
            const subcategories = await getDataGraphQL(getSubCategoriesByCategoryId, searchParameters);
            setBeSubcategories(subcategories.data.Subcategories.docs);
            dispatch(hideLoading());
        } catch (error) {
            showModal(dispatch, 'A intervenit o eroare: ', error);
            dispatch(hideLoading());
        }
    }, [dispatch, selectedCategories]);

    const fetchProducts = useCallback(async () => {
        try {
            dispatch(showLoading());

            const page = parseInt(searchParams.get('page') || '1', 10);

            const whereClause = {
                tipul_produsului: { in: [tip_produs] },
                categorie: { in: selectedCategories },
                subcategorie: { in: selectedSubcategories }
            };

            if (oferta) {
                whereClause.tip_oferta = { not_equals: "none" };
            }
            const searchParameters = {
                where: {
                    ...whereClause,
                    lista_preturi__tara: {
                        equals: userCountry
                    },
                    OR: [
                        { produs_delistat: { exists: false } },
                        { produs_delistat: { equals: false } }
                    ]
                },
                page,
                limit: 12,
                sort: sortOrder === 'asc' ? 'lista_preturi__pret' : '-lista_preturi__pret'
            };
            
            const data = await getDataGraphQL(getProductsWithFilters, searchParameters);
            const { docs, ...paginationData } = data.data.Produses;
            setProducts(docs);
            setPagination(paginationData);
            setProductsLoaded(true);  // Set this to true after products are loaded
        } catch (error) {
            showModal(dispatch, 'A intervenit o eroare: ', error);
        } finally {
            dispatch(hideLoading());
        }
    }, [dispatch, searchParams, userCountry, tip_produs, selectedCategories, selectedSubcategories, oferta, sortOrder]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    useEffect(() => {
        fetchSubcategories();
    }, [fetchSubcategories]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    useEffect(() => {
        if (!searchParams.get('page')) {
            const newParams = new URLSearchParams(searchParams);
            newParams.set('page', '1');
            setSearchParams(newParams, { replace: true });
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (productsLoaded && scrollPosition > 0) {
            setTimeout(() => {
                window.scrollTo(0, scrollPosition);
                dispatch(clearScrollPosition());
            }, 100);  // Small delay to ensure DOM has updated
        }
    }, [scrollPosition, dispatch, productsLoaded]);

    // Reset productsLoaded when fetch parameters change
    useEffect(() => {
        setProductsLoaded(false);
    }, [selectedCategories, selectedSubcategories, oferta, sortOrder]);

    const updateSearchParams = useCallback((updates) => {
        const newParams = new URLSearchParams(searchParams);
        Object.entries(updates).forEach(([key, value]) => {
            if (value === null) {
                newParams.delete(key);
            } else if (Array.isArray(value)) {
                newParams.delete(key);
                value.forEach(v => newParams.append(key, v));
            } else {
                newParams.set(key, value);
            }
        });
        setSearchParams(newParams, { replace: true });
    }, [searchParams, setSearchParams]);

    const handlePageChange = useCallback((newPage) => {
        updateSearchParams({ page: newPage.toString() });
    }, [updateSearchParams]);

    const handleCategoryClick = useCallback((categoryId) => {
        const newSelectedCategories = selectedCategories.includes(categoryId.toString())
            ? selectedCategories.filter(catId => catId !== categoryId.toString())
            : [...selectedCategories, categoryId.toString()];

        updateSearchParams({ 
            categorie: newSelectedCategories, 
            oferta: null,
            subcategorie: newSelectedCategories.length === 0 ? null : searchParams.getAll('subcategorie'),
            page: '1'
        });
    }, [selectedCategories, updateSearchParams, searchParams]);

    const handleSubcategoryClick = useCallback((subcategoryId) => {
        const newSelectedSubCategories = selectedSubcategories.includes(subcategoryId.toString())
            ? selectedSubcategories.filter(subcatId => subcatId !== subcategoryId.toString())
            : [...selectedSubcategories, subcategoryId.toString()];

        updateSearchParams({ subcategorie: newSelectedSubCategories, oferta: null, page: '1' });
    }, [selectedSubcategories, updateSearchParams]);

    const handleOfertaClick = useCallback(() => {
        updateSearchParams({ 
            oferta: oferta ? null : 'true',
            categorie: null,
            subcategorie: null,
            page: '1'
        });
    }, [oferta, updateSearchParams]);

    const handleSortChange = useCallback((newSortOrder) => {
        updateSearchParams({ sort: newSortOrder, page: '1' });
    }, [updateSearchParams]);

    return (
        <Layout>
            <>
                <div className="page_title">Produse {tip_produs.charAt(0).toUpperCase() + tip_produs.slice(1)}</div>
                <div className={styles.productLayout}>
                    <div>
                        <div className={styles.productFiltres}>
                            <div className={styles.searchContent}>
                                <div className={styles.searchContentText}>Sorteaza produse dupa</div>
                                <div className={styles.catContainer}>
                                    <div
                                        className={`${styles.individualCat} ${sortOrder === 'asc' ? styles.selected : ''}`}
                                        onClick={() => handleSortChange('asc')}
                                    >
                                        <div className={styles.checkBox}>
                                            {sortOrder === 'asc' && (
                                                <svg className="checkmark" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                </svg>
                                            )}
                                        </div>
                                        <div className={styles.categoryName}>Pret Crescator</div>
                                    </div>
                                    <div
                                        className={`${styles.individualCat} ${sortOrder === 'desc' ? styles.selected : ''}`}
                                        onClick={() => handleSortChange('desc')}
                                    >
                                        <div className={styles.checkBox}>
                                            {sortOrder === 'desc' && (
                                                <svg className="checkmark" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                </svg>
                                            )}
                                        </div>
                                        <div className={styles.categoryName}>Pret Descrescator</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.searchContent}>
                                <div className={styles.searchContentText}>Particularitati Produs</div>
                                <div className={styles.catContainer}>
                                    <div className={styles.individualCat} onClick={handleOfertaClick}>
                                        <div className={styles.checkBox}>
                                            {oferta && (
                                                <svg className="checkmark" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                </svg>
                                            )}
                                        </div>
                                        <div className={styles.categoryName}>Produse la Oferta</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.searchContent}>
                                {beCategories.length > 0 && <div className={styles.searchContentText}>Brand</div>}
                                <div className={styles.catContainer}>
                                    {beCategories.map((item) => (
                                        <div
                                            key={item.id}
                                            className={`${styles.individualCat} ${selectedCategories.includes(item.id.toString()) ? styles.selected : ''}`}
                                            onClick={() => handleCategoryClick(item.id)}
                                        >
                                            <div className={styles.checkBox}>
                                                {selectedCategories.includes(item.id.toString()) && (
                                                    <svg className="checkmark" viewBox="0 0 24 24">
                                                        <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                    </svg>
                                                )}
                                            </div>
                                            <div className={styles.categoryName}>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.searchContent}>
                                {beSubcategories.length > 0 && (
                                    <>
                                        <div className={styles.searchContentText}>Categorii Produse</div>
                                        <div className={styles.catContainer}>
                                            {beSubcategories.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className={`${styles.individualCat} ${selectedSubcategories.includes(item.id.toString()) ? styles.selected : ''}`}
                                                    onClick={() => handleSubcategoryClick(item.id)}
                                                >
                                                    <div className={styles.checkBox}>
                                                        {selectedSubcategories.includes(item.id.toString()) && (
                                                            <svg className="checkmark" viewBox="0 0 24 24">
                                                                <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                            </svg>
                                                        )}
                                                    </div>
                                                    <div className={styles.categoryName}>{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {products.length > 0 ? (
                        <div className={styles.productContainer}>
                            {products.map((product) => (
                                <Product key={product.id} product={product} />
                            ))}
                        </div>
                    ) : (
                        <div className={styles.emptyBasketContainer}>
                            <div className={styles.individualEmptyBasket}>
                                Din pacate, produsele {tip_produs.charAt(0).toUpperCase() + tip_produs.slice(1)} nu sunt disponibile.
                            </div>
                            <div className={styles.individualEmptyBasket}>
                                Verificati filtrele sau cautati / rasfoiti alte produse si adaugati-le in cos.
                            </div>
                        </div>
                    )}
                </div>
                {products.length > 0 && (
                    <Pagination props={pagination} onPageChange={handlePageChange} />
                )}
            </>
        </Layout>
    );
};

export default Produse;