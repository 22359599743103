// src/pages/Login/Login.js

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserStatus, setUserDetails } from '../../features/user/userSlice';
import showModal from '../../utils/modalFunction';
import styles from './Login.module.css';
import Modal from '../../components/Modal/Modal';
import { Link } from 'react-router-dom';

const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL; // Access the base API URL

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/clienti/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
            credentials: 'include',
            });

            if (response.ok) {
                const res = await response.json();
                dispatch(setUserDetails(res.user));
                dispatch(setUserStatus(true));
            } else {
                const errorData = await response.json();
                showModal(dispatch, 'Eroare!', errorData.errors[0].message);
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };


    const handleForgotPasswordSubmit = (event) => {
        setForgotPassword(false)

        // Logic for handling forgot password form submission
        // You can implement email validation and dispatch actions accordingly
    };

    const handleCancelForgotPassword = () => {
        setForgotPassword(false);
        setForgotEmail('');
    };

    return (
        <div className={styles.loginContainer}>
            <Modal className={styles.modalUi} />
            <div className={styles.loginImage}></div>
            {forgotPassword === false ? (
                <div className={styles.loginWhiteContainer}>
                    <img className={styles.productPicture} src={`/logo.png`} alt='logo' />
                    <p>Introduceti datele contului</p>
                    <div style={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic' }}>* Pentru crearea unui cont, contactati-ne la nr de telefon 0724242665 sau la adresa de e-mail vanzari@gepeto.ro</div>
                    <div className={styles.loginForm}>
                        <div className={styles.formGroup}>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Adresa de e-mail *'
                                className={styles.inputField}
                            />

                        </div>
                        <div className={styles.formGroup}>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Parola *'
                                className={styles.inputField}
                            />
                        </div>
                        <button type="submit" onClick={handleLoginSubmit} className={styles.loginButton}>
                            <span>Autentifica-te</span>
                        </button>
                        {/* <div className={styles.forgotPassword} onClick={() => { setForgotPassword(true) }}>Am uitat parola</div> */}
                    </div>
                </div>
            ) : (
                <div className={styles.loginWhiteContainer}>
                    <p style={{ marginBottom: '1rem' }}>Ai uitat parola?</p>
                    <p style={{ textAlign: 'center', fontSize: '14px' }}>Introdu adresa de e-mail in campul de mai jos si vei primi pe e-mail un link cu resetarea parolei. </p>
                    <div className={styles.loginForm}>
                        <div className={styles.formGroup}>
                            <input
                                type="email"
                                id="forgotEmail"
                                name="forgotEmail"
                                value={forgotEmail}
                                onChange={(e) => setForgotEmail(e.target.value)}
                                placeholder='Adresa de e-mail *'
                                className={styles.inputField}
                            />
                        </div>
                        <button type="submit" className={styles.loginButton} onClick={() => { handleForgotPasswordSubmit(); setForgotEmail('') }}>
                            <span>Trimite</span>
                        </button>
                        <button type="submit" className={styles.loginButton} onClick={() => { handleCancelForgotPassword(); setForgotEmail(''); }}>
                            <span>Anuleaza</span>
                        </button>

                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
