import styles from '../../pages/Account/Account.module.css';


const ClientInfo = ({ client }) => {
    return (
        <div className={styles.contentContainer}>
            <div className={styles.sectionTitle}>Informatii client</div>
            <div className={styles.accountDetailsContainer}>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Societate
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.companie || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Cod Client
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.cod_client || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Email
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.email || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Adresa sediu social
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.adresa_sediu_social || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Tara
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.tara || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        CUI
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.cui || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Nr Registrul Comertului
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.reg_com || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        IBAN
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.iban || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Banca
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.banca || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Tier
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.tier || 'N/A'}
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default ClientInfo;