export const fetchProductsByCategory = `
  query GetProductsByCategory($categoryIds: [JSON]) {
    Produses(
      where: {
        categorie: {
          in: $categoryIds
        }
      },
    ) {
      docs {
        id
        nume_produs
        categorie {
          id
          name
        }
      }
    }
  }
`;
export const fetchProductById = `
  query FetchProductById($productId: Int!) {
    Produse(id: $productId) {
      id
      nume_produs
      cod_produs_intern
      tva
      valoare_sgr
      mod_comercializare
      gramaj
              unitate_vanzare
        particularitati_produs
      bucati_pe_bax
      pret_pe_bucata
      informatii_nutritionale
      descriere
      imagine {
        id
        url
      }
      tipul_produsului
      categorie {
        id
        name
      }
      subcategorie {
        id
        name
      }
      tip_oferta
      flat_discount_percentage
      prag_oferta {
        prag_cantitate
        valoare_oferta
      }
      lista_preturi {
        tara
        pret
      }
    }
  }
`;

export const getProductsByManufacturer = `
      query GetProductsByManufacturer($magazin: [Produse_tipul_produsului_Input]) {
        Produses(
          where: {
            tipul_produsului: {
              in: $magazin
            }
          }
        ) {
          docs {
            id
            nume_produs
            cod_produs_intern
            tva
            valoare_sgr
            mod_comercializare
            gramaj
                    unitate_vanzare
        particularitati_produs
            bucati_pe_bax
            pret_pe_bucata
            informatii_nutritionale
            unitate_vanzare
            particularitati_produs
            descriere
            imagine{
              id
              url
            },
            tipul_produsului
            categorie {
              id
              name
            },
            subcategorie{
              id
              name
            }
            tip_oferta
            flat_discount_percentage
            prag_oferta {
            prag_cantitate
            valoare_oferta
                  lista_preturi {
        tara
        pret
      }
          }
        }
  }
`;

export const getProductsWithFilters = `
 query GetProductsWithFilters(
  $where: Produse_where,
  $page: Int,
  $limit: Int,
  $sort: String
) {
  Produses(
    where: $where,
    page: $page,
    limit: $limit,
    sort: $sort
  ) {
    docs {
      id
      nume_produs
      cod_produs_intern
      tva
      valoare_sgr
      mod_comercializare
      gramaj
              unitate_vanzare
        particularitati_produs
      bucati_pe_bax
      pret_pe_bucata
      descriere
      imagine {
        id
        url
      }
      categorie {
        id
        name
      }
      subcategorie {
        id
        name
      }
      tip_oferta
      flat_discount_percentage
      prag_oferta {
        prag_cantitate
        valoare_oferta
      }
      lista_preturi {
        tara
        pret
      }
    }
    totalDocs
    limit
    totalPages
    page
    pagingCounter
    hasPrevPage
    hasNextPage
    prevPage
    nextPage
  }
}
`;

export const getRecommendedProducts = `
  query GetRecommendedProducts($limit: Int) {
    Produses(
      where: {
        produs_recomandat: {
          equals: true
        }
      },
      limit: $limit
    ) {
      docs {
        id
        nume_produs
        cod_produs_intern
        tva
        valoare_sgr
        mod_comercializare
        gramaj
        unitate_vanzare
        particularitati_produs
        bucati_pe_bax
        pret_pe_bucata
        descriere
        imagine {
          id
          url
        }
        categorie {
          id
          name
        }
        subcategorie {
          id
          name
        }
        tip_oferta
        flat_discount_percentage
        prag_oferta {
          prag_cantitate
          valoare_oferta
          # Add other fields as necessary
        }
              lista_preturi {
        tara
        pret
      }
      }
    }
  }
`;

export const getProductsWithOfferType = `
  query GetProductsWithOffer($limit: Int) {
    Produses(
      where: {
        tip_oferta: {
          in: [flat, cumpara_x_primesti_y_procentaj, cumpara_x_primesti_y]
        }
      },
      limit: $limit
    ) {
      docs {
        id
        nume_produs
        cod_produs_intern
        tva
        valoare_sgr
        mod_comercializare
        gramaj
                unitate_vanzare
        particularitati_produs
        bucati_pe_bax
        pret_pe_bucata
        descriere
        imagine {
          id
          url
        }
        categorie {
          id
          name
        }
        subcategorie {
          id
          name
        }
        tip_oferta
        flat_discount_percentage
        prag_oferta {
          prag_cantitate
          valoare_oferta
        }
              lista_preturi {
        tara
        pret
      }
      }
    }
  }
`;