import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

import basketReducer from './features/basket/basketSlice';
import uiReducer from './features/ui/uiSlice';
import userReducer from './features/user/userSlice';
import sortingReducer from './features/sorting/sortingSlice';
import scrollReducer from './features/scrolling/scrollPositionSlice'

const rootReducer = combineReducers({
  basket: basketReducer,
  ui: uiReducer,
  user: userReducer,
  sorting: sortingReducer,
  scrollPosition: scrollReducer
});

const persistConfig = {
  key: 'root', // Key for storing state in localStorage
  storage, // Storage engine to use (e.g., localStorage)
  whitelist: ['user', 'sorting', 'basket'], // Reducers you want to persist
  // Other options if needed
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // Other store configurations if needed
});

export const persistor = persistStore(store);