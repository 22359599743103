import React from 'react';
import Layout from '../../components/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearBasket } from '../../features/basket/basketSlice';
import { sendData } from '../../utils/fetchData';
import showModal from '../../utils/modalFunction';
import styles from './Checkout.module.css';

const Checkout = () => {
    const productsFromBasket = useSelector(state => state.basket);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [observatii, setObservatii] = React.useState('');
    const { punct_de_lucru } = location.state;

    const handleSendOrder = async () => {
        const data = await sendData('/comenzi/createOrder', JSON.stringify({
            'punct': punct_de_lucru,
            'observatii': observatii
        }));
        if (data.error) {
            console.error(data.error);
            showModal(dispatch, 'Eroare!', data.error);
        } else {
            //redirect user to homepage after successfully placing the order
            dispatch(clearBasket())
            navigate('/');
            showModal(dispatch, 'Felicitari!', 'Comanda dumneavoastra a fost inregistrata cu succes!');
        }
    }


    return (
        <Layout>
            <div className={styles.checkoutPage}>
                <h2 className={styles.pageTitle}>Sumar</h2>
                <button onClick={() => navigate(-1)} className={styles.backButton} aria-label="Go back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 12H7.83L11.41 8.41L10 7L4 13L10 19L11.41 17.59L7.83 14H20V12Z" fill="#333333" />
                    </svg>
                </button>
                <div className={styles.checkoutContainer}>
                    <table className={styles.productTable}>
                        <thead>
                            <tr>
                                <th>Nume</th>
                                <th>Cantitate</th>
                                <th>Tip comercializare</th>
                                <th>TVA</th>
                                <th>Pret final</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(productsFromBasket.items).map(prodId => {
                                const product = productsFromBasket.items[prodId];
                                return (
                                    <tr key={prodId}>
                                        <td data-label="Nume">{product.name}</td>
                                        <td data-label="Cantitate">{product.quantity} {product.free_units > 0 ? `+ ${product.free_units} gratis` : null}</td>
                                        <td data-label="Tip comercializare">{product.product_retail}</td>
                                        <td data-label="TVA">{product.tva}%</td>
                                        <td data-label="Pret final">€{product.price}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.observationsContainer}>
                        <label htmlFor="observations" className={styles.observationsLabel}>Observatii Comanda:</label>
                        <textarea
                            id="observations"
                            value={observatii}
                            onChange={(e) => setObservatii(e.target.value)}
                            className={styles.observationsInput}
                        />
                    </div>
                    <div className={styles.totalContainer}>
                        <span className={styles.totalLabel}>Total:</span>
                        {/* if offer is smaller than the initial price, dont show it  */}
                        <div>
                            {
                                (productsFromBasket.total_suma_salvata + productsFromBasket.total_plata) < productsFromBasket.total_plata ?
                                    <span className={styles.originalPrice}>€ {Number(productsFromBasket.total_suma_salvata + productsFromBasket.total_plata)}</span>
                                    :
                                    <span className={styles.totalAmount}>€ {productsFromBasket.total_plata}</span>

                            }
                        </div>

                    </div>
                    <button onClick={handleSendOrder} className={styles.placeOrderButton}>Plaseaza comanda</button>
                </div>
            </div>
        </Layout>
    );
};

export default Checkout;