import React, { useState, useEffect } from 'react';
import styles from '../../pages/Account/Account.module.css';
import { useSelector } from 'react-redux';
import { fetchData } from '../../utils/fetchData';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../../features/ui/uiSlice';
import showModal from '../../utils/modalFunction';

const PastOrders = () => {
    const [orders, setOrders] = useState(null);
    const [expandedOrder, setExpandedOrder] = useState(null);
    const userDetails = useSelector(state=>state.user.userDetails)
    const dispatch = useDispatch();

    const fetchOrders = async () => {
        try {
            dispatch(showLoading());
            const data = await fetchData('/comenzi?limit=0');
            setOrders(data.docs);
        } catch (err) {
            showModal(dispatch, 'A aparut o eroare', err.toString());
        } finally {
            dispatch(hideLoading());
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const toggleOrderDetails = (orderNumber) => {
        setExpandedOrder((prev) => (prev === orderNumber ? null : orderNumber));
    };

    return (
        <div className={styles.contentContainer}>
            <div className={styles.sectionTitle}>Comenzi Efectuate</div>
            {orders?.length > 0 ? (
                <>
                    {orders.map((order) => (
                        <div style={{ width: '100%', marginTop: '30px' }} onClick={() => toggleOrderDetails(order.numar_comanda)}>
                            <div key={order.numar_comanda} className={styles.orderItem}>
                                <div className={styles.orderContainer} >
                                    {order.numar_comanda}, {order.punct_de_lucru.adresa_punct_de_lucru}
                                </div>
                                {expandedOrder === order.numar_comanda && (
                                    <div className={styles.accountDetailsContainer}>
                                        <div className={`${styles.sectionContainer} ${styles.relativeTitle}`}>
                                            <div className={`${styles.sectionContainerTitle}`}>
                                                Numar Comanda
                                            </div>
                                            {/* <div className={styles.placeOrder} onClick={(event) => {event.stopPropagation()}}>Repeta comanda</div> */}
                                            <div className={styles.sectionContainerContent}>{order.numar_comanda}</div>
                                        </div>
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionContainerTitle}>Data Comanda</div>
                                            <div className={styles.sectionContainerContent}>{order.data_comanda.split('T')[0].split('-').reverse().join('-')}</div>
                                        </div>
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionContainerTitle}>Punct de lucru</div>
                                            <div className={styles.sectionContainerContent}>Denumire: {order.punct_de_lucru.denumire} - ({order.punct_de_lucru.adresa_punct_de_lucru})</div>
                                        </div>
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionContainerTitle}>Status Comanda</div>
                                            <div className={styles.sectionContainerContent}>{order.status.replace('_', ' ')}</div>
                                        </div>
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionContainerTitle}>Produse Comandate</div>
                                            <div className={styles.sectionContainerContent}>{order.produse.map((produs, index) => {
                                                const countryPrice = produs.produs.lista_preturi.find(item => item.tara === userDetails.tara);
                                                return (
                                                    <div className={styles.individualOrderProductContainer}>
                                                        <div>{index + 1}</div>
                                                        <div>{produs.produs.nume_produs}</div>
                                                        <div>TVA: {produs.produs.tva}%</div>
                                                        <div>Cantitate: {produs.cantitate}</div>
                                                        <div>{countryPrice.pret} €</div>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                            <div className={styles.sectionContainer}>
                                                <div className={styles.sectionContainerTitle}>Total</div>
                                                <div className={styles.sectionContainerContent}>€ {order.total_comanda}</div>
                                            </div>
                                        </div>
                                        {/* Add more fields as necessary */}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <div style={{ marginTop: '20px' }}>Nicio comanda efectuata.</div>
            )}
        </div>
    );
};

export default PastOrders;
