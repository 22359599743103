import React from 'react';
import styles from './Acasa.module.css'; // Import CSS module
import Layout from '../../components/Layout/Layout';
import MySwiper from '../../components/MySwiper';
import { Link } from 'react-router-dom';
import Product from '../../components/Product/Product';
import { getDataGraphQL } from '../../utils/fetchData';
import { getRecommendedProducts, getProductsWithOfferType } from '../../utils/graphQL/productQueries';
import { showLoading, hideLoading } from '../../features/ui/uiSlice';
import { useDispatch } from 'react-redux';
import showModal from '../../utils/modalFunction';

const Acasa = () => {
    const dispatch = useDispatch();
    const [recommendedProducts, setRecommendedProducts] = React.useState(null);
    const [productsWithOffer, setProductsWithOffer] = React.useState([]);

    const fetchRecommendedProducts = async (limit = 9) => {
        dispatch(showLoading());
        try {
            const data = await getDataGraphQL(getRecommendedProducts, { limit });
            setRecommendedProducts(data?.data?.Produses?.docs);
        } catch (error) {
            console.error('Failed to fetch recommended products:', error);
            showModal(dispatch, 'A aparut o eroare', error.toString());
        } finally {
            dispatch(hideLoading());
        }
    };
    const fetchProductsWithOffer = async (limit = 9) => {
        dispatch(showLoading());
        const data = await getDataGraphQL(getProductsWithOfferType, { limit });
        setProductsWithOffer(data?.data?.Produses?.docs);
        dispatch(hideLoading());
    }


    React.useEffect(() => {
        fetchRecommendedProducts();
        fetchProductsWithOffer();
    }, [])
    return (
        <Layout>
            <div style={{ width: '100vw', height: '28vw', minHeight: '12rem' }}>
                <MySwiper />
            </div>
            <div className={styles.homeContentContainer}>
                <div className={styles.homeBorder}></div>
                {
                    productsWithOffer?.length!==0 ? <>
                        <h2 style={{ textAlign: 'center', fontSize:'2em', paddingTop:'0px', marginTop: '0px' }}>Ofertele Noastre</h2>
                        <div className={styles.productContainer}>
                            {productsWithOffer &&
                                productsWithOffer.map((product) => {
                                    return (
                                        <Product key={product.id} product={product} />
                                    )
                                })
                            }
                        </div></>:null
                }

                <IntroSection />
                <h2 style={{ textAlign: 'center' }}>Produse Recomandate</h2>
                <div className={styles.productContainer}>
                    {recommendedProducts &&
                        recommendedProducts.map((product) => {
                            return (
                                <Product key={product.id} product={product} />
                            )
                        })
                    }
                </div>
            </div>
        </Layout>
    );
}

export default Acasa;


const IntroSection = () => {
    return (
        <section className={styles.introSection}>
            <h2 className={styles.introTitle} style={{paddingTop: '0px'}}>Pentru un Plus de Savoare</h2>
            <p className={styles.introText}>
                Descopera produsele <Link to="/produse/gepeto" className={styles.gepetoLink}>Gepeto</Link> si adauga un strop de rafinament fiecarei mese. Calitate si gust la superlativ.
            </p>
            <button className={styles.ctaButton}><Link to="/produse/gepeto" className={styles.gepetoLink2}>Comanda Acum</Link></button>
        </section>
    );
};
