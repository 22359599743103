import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import showModal from '../../utils/modalFunction';
import { logOutUser } from '../user/userSlice';

const appUrl = process.env.REACT_APP_API_URL;

export const addItemAsync = createAsyncThunk(
    'basket/addItemAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetch(`${appUrl}/cos/add-item/${payload.productId}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ quantity: payload.quantity })
            });

            // Check if the response status indicates success
            if (!response.ok) {
                const errorResponse = await response.json();
                showModal(dispatch, 'Eroare!', errorResponse.message);
                return rejectWithValue(errorResponse);
            }

            // Dispatch populateBasketAsync action
            dispatch(populateBasketAsync());
            return payload;
        } catch (error) {
            console.log('in error');

            // Handle network errors or other unexpected errors
            return rejectWithValue(error.message || 'An unknown error occurred');
        }
    }
);

export const modifyProductQuantityAsync = createAsyncThunk(
    'basket/modifyProductQuantityAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetch(`${appUrl}/cos/update-item/${payload.productId}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ quantity: payload.howMany })
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                showModal(dispatch, 'Eroare!', errorResponse.message);
                return rejectWithValue(errorResponse);
            }
            dispatch(populateBasketAsync());
            return payload;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeItemAsync = createAsyncThunk(
    'basket/removeItemAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetch(`${appUrl}/cos/remove-item/${payload.productId}`, {
                method: 'PATCH',
                credentials: 'include',
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                showModal(dispatch, 'Eroare!', 'Nu am putut inlatura articolul.');
                return rejectWithValue(errorResponse);
            }
            dispatch(populateBasketAsync());
            return payload.productId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const substractItemAsync = createAsyncThunk(
    'basket/substractItemAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetch(`${appUrl}/cos/substract-item/${payload.productId}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                showModal(dispatch, 'Eroare!', 'Nu am putut inlatura articolul.');
                return rejectWithValue(errorData);
            }
            dispatch(populateBasketAsync());
            return payload.productId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const populateBasketAsync = createAsyncThunk(
    'basket/populateBasketAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetch(`${appUrl}/cos`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) {
                if(response.status === 403){
                    dispatch(logOutUser())
                }
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }
            const basketData = await response.json();
            return basketData;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    items: {},
    showModal: false,
    productIdToDelete: null,
    total_plata: null,
    total_suma_salvata: null,
    basketData:{}
};

const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        addItem: (state, action) => {
            const { productId } = action.payload;
            if (state.items[productId]) {
                state.items[productId].count += 1;
            } else {
                state.items[productId] = {
                    id: productId,
                    count: 1,
                    productPicture: '',
                    initialPrice: null,
                    productPrice: null,
                };
            }
        },
        substractItem: (state, action) => {
            const { productId } = action.payload;
            const existingItem = state.items[productId];
            if (existingItem && existingItem.count > 0) {
                if (existingItem.count === 1) {
                    state.showModal = true; // Show modal for confirmation
                    state.productIdToDelete = productId;
                } else {
                    state.items[productId] = {
                        ...existingItem,
                        count: existingItem.count - 1,
                    };
                }
            }
        },
        modifyItemUnit: (state, action) => {
            const { productId, howMany } = action.payload;
            if (state.items[productId]) {
                if (howMany > 0) {
                    state.items[productId].quantity = howMany;
                }
            }
        },
        removeItem: (state, action) => {
            const { productId } = action.payload;
            state.showModal = true; // Show modal for confirmation
            state.productIdToDelete = productId;
        },
        clearBasket: () => {
            return initialState;
        },
        sendOrder: (state, action) => {
            state.showModal = true;
            clearBasket();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addItemAsync.fulfilled, (state, action) => {
                const { productId, quantity } = action.payload;
                if (state.items[productId]) {
                    state.items[productId].quantity += quantity;
                } else {
                    state.items[productId] = {
                        id: productId,
                        quantity: 1,
                        productPicture: action.payload.productPicture || '',
                        initialPrice: action.payload.initialPrice,
                        productPrice: action.payload.productPrice,
                    };
                }
            })
            .addCase(removeItemAsync.fulfilled, (state, action) => {
                const productId = action.payload;
                delete state.items[productId];
            })
            .addCase(populateBasketAsync.fulfilled, (state, action) => {
                state.total_plata = action.payload.docs[0].total_plata;
                state.total_suma_salvata = action.payload.docs[0].total_suma_salvata;
                state.items = {};

                const basketData = action.payload.docs[0].listaProduse;
                basketData.forEach((product) => {
                    state.items[product.produse.id] = {
                        id: product.produse.id,
                        quantity: product.cantitate,
                        image: product.produse.imagine.url, 
                        price: product.subtotal,
                        price_for_all_units: product.produse.pret_final,
                        price_with_offer: product.subtotal_cu_oferta,
                        economy_amount: product.suma_economisita,
                        tva: product.produse.tva,
                        name: product.produse.nume_produs,
                        product_type: product.produse.tipul_produsului,
                        product_code: product.produse.cod_produs_intern,
                        unit_price: product.produse.pret_pe_bucata,
                        product_retail: product.produse.mod_comercializare,
                        weight: product.produse.gramaj,
                        offer_type: product.produse.tip_oferta,
                        free_units: product.produse_gratis_oferta,
                        prag_oferta: product.produse.prag_oferta,
                        discount_percentage: product.produse.flat_discount_percentage
                    };
                });
            })
            .addCase(substractItemAsync.fulfilled, (state, action) => {
                const productId = action.payload;
                const existingItem = state.items[productId];
                if (existingItem && existingItem.quantity > 0) {
                    existingItem.quantity -= 1;
                    if (existingItem.quantity === 0) {
                        delete state.items[productId];
                    }
                }
            })
            .addCase(modifyProductQuantityAsync.fulfilled, (state, action) => {
                const { productId, howMany } = action.payload;
                if (state.items[productId]) {
                    if (howMany > 0) {
                        state.items[productId].quantity = howMany;
                    }
                }
            });
    }
});

export const { removeItem, addItemToCart, substractItem, modifyItemUnit, confirmDelete, cancelDelete, clearBasket } = basketSlice.actions;
export default basketSlice.reducer;

export const countBasketItems = state => {
    let itemCount = 0;
    Object.keys(state.basket.items).map((item)=>{
        itemCount +=state.basket.items[item].quantity;
    })
    return itemCount;

};