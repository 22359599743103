import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItemAsync } from '../../features/basket/basketSlice';
import styles from './Product.module.css';
import QuantitySelector from './QuantitySelector';
import { setShowComponent, showLoading, hideLoading } from '../../features/ui/uiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import AddedToCartModal from '../Modal/AddedToCartModal';
import { setScrollPosition } from '../../features/scrolling/scrollPositionSlice';

const Product = ({ product }) => {
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appUrl = process.env.REACT_APP_URL;
    const [showAddedToCart, setShowAddedToCart] = useState(false);
    const userCountry = useSelector(state => state.user.userDetails.tara);

    const [offerType, setOfferType] = useState('');

    const { price, priceWithoutVAT } = useMemo(() => {
        const priceObj = product?.lista_preturi.find(item => item.tara === userCountry);
        const price = priceObj ? priceObj.pret : 0;
        const priceWithoutVAT = price / (1 + (product?.tva / 100));
        return { price, priceWithoutVAT };
    }, [product, userCountry]);

    const handleAddToBasket = () => {
        if (quantity > 0) {
            dispatch(showLoading());
            dispatch(addItemAsync({ productId: product.id, quantity }))
                .then((action) => {
                    if (addItemAsync.fulfilled.match(action)) {
                        setQuantity(1);
                        dispatch(setShowComponent(true));
                    }
                })
                .catch((error) => {
                    console.error('Unexpected error:', error);
                })
                .finally(() => {
                    dispatch(hideLoading());
                });
        }
    };

    React.useEffect(() => {
        const renameOfferType = () => {
            switch (product.tip_oferta) {
                case 'flat':
                    setOfferType(`Discount ${product.flat_discount_percentage}%`);
                    break;
                case 'cumpara_x_primesti_y':
                    setOfferType(`${product.prag_oferta[0].prag_cantitate} + ${product.prag_oferta[0].valoare_oferta} gratis`);
                    break;
                case 'cumpara_x_primesti_y_procentaj':
                    setOfferType(`-${product.prag_oferta[0].valoare_oferta}% la minim ${product.prag_oferta[0].prag_cantitate} unitati`);
                    break;
                default:
                    setOfferType('');
            }
        };
        renameOfferType();
    }, [product.tip_oferta]);

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    const handleProductClick = useCallback((e) => {
        e.preventDefault();
        const scrollPosition = window.scrollY;
        dispatch(setScrollPosition(scrollPosition));
        navigate(`/produs/${product?.id}`);
    }, [navigate, product?.id, dispatch]);

    return (
        <div className={styles.productContainer}>
            {product?.tip_oferta !== 'none' && (
                <div className={styles.banderoleContainer}>
                    <div className={styles.banderole}>
                        Oferta %
                    </div>
                </div>
            )}
            {product?.tip_oferta !== 'none' &&
                <div className={styles.offerType}>
                    {offerType}
                </div>
            }
            <div className={styles.productImageContainer}>
                <Link to={`/produs/${product?.id}`} className={`${styles.productImageContainer}`} onClick={handleProductClick}>
                    <img className={styles.productPicture} src={`${appUrl}${product?.imagine?.url}`} alt={product?.nume_produs} />
                </Link>
            </div>
            <Link to={`/produs/${product?.id}`} className={styles.productTitleLink} onClick={handleProductClick}>
                <div className={`${styles.productTitle} ${styles.productPadding}`}>
                    {product?.nume_produs}
                </div>
            </Link>
            <div className={styles.insideProductContainer}>
                <div className={styles.productPadding}>
                    Cod: {product?.cod_produs_intern}
                </div>
                {product?.tip_oferta === 'flat' ? (
                    <div className={`${styles.productDiscountContainer} ${styles.productPadding}`}>
                        <div className={styles.productInitialPrice}>€ {price.toFixed(2).replace('.', ',')}</div>
                        <div className={styles.productPrice}>
                            € {(price - price * product?.flat_discount_percentage / 100).toFixed(2)} / {product.mod_comercializare}
                        </div>
                    </div>
                ) : (
                    <div className={`${styles.productPrice} ${styles.productPadding}`}>
                        € {price.toFixed(2).replace('.', ',')} / {product.mod_comercializare}
                    </div>
                )}
                <div className={styles.productPadding} style={{textAlign:'center'}}>
                    *mod comercializare - {product.unitate_vanzare}
                </div>
            </div>
            <div style={{ width: '100%', marginTop: 'auto' }}>
                <div>
                    <QuantitySelector
                        productId={product?.id}
                        quantity={quantity}
                        handleQuantityChange={handleQuantityChange}
                        context="productCard"
                    />
                </div>
                <button onClick={() => { handleAddToBasket(); setShowAddedToCart(true) }} className={styles.productBasket}>
                    <AddedToCartModal
                        isVisible={showAddedToCart}
                        onHide={() => setShowAddedToCart(false)}
                    />
                </button>
            </div>
        </div>
    );
};

export default Product;